import React from "react";

import * as styles from "./Author.module.scss";

const Author = () => (
  <div className={styles.author}>
    <p className={styles.bio}>
      Happy to hear from you at <a href="mailto:matt@matt.fyi">matt@matt.fyi</a>{" "}
      or <a href="https://twitter.com/mattjustfyi">@mattjustfyi</a> on X.
    </p>
  </div>
);

export default Author;
