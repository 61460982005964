import React from "react";

import * as styles from "./Content.module.scss";

interface Props {
  title: string;
  body: string;
  category: string;
  tags?: Array<string>;
}

const Disclaimer: React.FC<{}> = () => (
  <div className={styles.disclaimer}>This is not expert advice 🙂</div>
);

const Content: React.FC<Props> = ({ body, title, category, tags }: Props) => (
  <div className={styles.content}>
    <h1 className={styles.title}>{title}</h1>
    {category === "Life" && (!tags || !tags.includes("stoicism")) && (
      <Disclaimer />
    )}
    <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
